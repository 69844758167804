import React, { useEffect } from "react";
import Button from "../Button/Button";
import './header.css'
import '../font.css'
import {Link} from 'react-router-dom'

const Header = () => { 

  useEffect(()=>{ 
    const nav = document.querySelector('.nav');
    let lastScrollY = window.scrollY; 
    window.addEventListener('scroll',()=>{
      if(lastScrollY < window.scrollY){
        nav.classList.add('nav--hidden');
      }else{
        nav.classList.remove('nav--hidden');
      }  
      lastScrollY =window.scrollY
    })
  },[])
    
  return (
    <div class="nav">
  
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <Link to='/' style={{marginLeft:' ',textDecoration: 'none'}}>
              <h3 id="logo" class="font_id" style={{color:'antiquewhite'}}>NEW<span style={{color:'red',}}>STYLE</span></h3>
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation" 
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0" id="nav_ul" >

              <Link to='/' style={{textDecoration: 'none'}}>
                  <li class="nav-item" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
                      <Button width={80}  dWidth={100} name={"Home"} scolor={"white"} />
                  </li>
              </Link>

              <Link to='/about-us' style={{textDecoration: 'none'}}>
                  <li class="nav-item" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
                      <Button width={100} dWidth={120} color={"white"}  name={"About Us"} scolor={"white"}/>
                  </li>
              </Link>

              {/* <Link to='/service' style={{textDecoration: 'none'}}>
                  <li class="nav-item" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
                      <Button width={90}  dWidth={110} color={"white"} name={"Service"} scolor={"white"}/>
                  </li>
              </Link> */}
               
                  <li class="nav-item">
                    <div class="dropdown">
                        <div role="button" data-bs-toggle="dropdown" aria-expanded="false" >
                        <Button width={90} dWidth={110} color={"white"}  name={`Service`} scolor={"white"}/>
                        </div>

                        <ul class="dropdown-menu p-2" style={{background:'rgba(0,0,0,0.6)'}}>
                            <Link to='/bridal' style={{textDecoration: 'none'}}>
                                <li class="nav-item" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
                                    <Button width={80}  dWidth={100} color={"white"} name={"Bridal"} scolor={"white"}/>
                                </li>
                            </Link> 
                            <Link to='/hair' style={{textDecoration: 'none'}}>
                                <li class="nav-item" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
                                    <Button width={70}  dWidth={90} color={"white"} name={"Hair"} scolor={"white"}/>
                                </li>
                            </Link>
                            <Link to='/beauty' style={{textDecoration: 'none'}}>
                                <li class="nav-item" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
                                    <Button width={80}  dWidth={100} color={"white"} name={"Beauty"} scolor={"white"}/>
                                </li>
                            </Link>  
                        </ul>
                    </div>
                  </li> 

              <Link to='/contact-us' style={{textDecoration: 'none'}}>
                  <li class="nav-item" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
                      <Button width={110} dWidth={150} color={"white"}  name={"Contact Us"} scolor={"white"}/>
                  </li>
              </Link>
            </ul>


            <ul class="navbar-nav me-auto mb-2 mb-lg-0" id='appin'>

              <Link to='/book-an-appointment' style={{textDecoration: 'none'}}>
                  <li class="nav-item" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
                      <Button width={180} dWidth={220} color={"white"} name={"Book an Appointment"} scolor={"white"}/>
                  </li> 
              </Link>

            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
