import React, { useEffect } from 'react'
import './bridal1.css'
import Video from './Video'
import Button from '../Button/Button'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

 
const Bridal = () => {
    useEffect (()=>{
        document.body.scrollTop = 0;
        Aos.init({duration:2000});
      },[])
  return (
    <div style={{width:'80%',margin:'auto', textAlign:'left',backgroundImage:`url(${require('../../images&videos/Images/homepage/make-up-brush1.png')})`,backgroundRepeat:'no-repeat',backgroundSize: '50% 100%',backgroundPosition:'right'}}>
        <h1 class='hfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">Bridal Packages</h1>
        <h1 class='hSfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0"> Bridal Treatment</h1>
        <br />
        <Link to='/bridal' style={{textDecoration:'none'}}>
            <Button width={120} dWidth={150} color={"black"}  name={"View Details"} scolor={"black"}/>
        </Link>
        <br />
        <br />
        <div class='bridal_tret'>
            <div class='up'>
                <Video img={require('../../images&videos/Images/bridalMakeover/bt6.png')}  video={require('../../images&videos/videos/hairVideos/video1.mp4')}/>
            </div>
            <div class='dn'>
                <Video img={require('../../images&videos/Images/bridalMakeover/bt7.png')} video={require('../../images&videos/videos/hairVideos/video2.mp4')}/>
            </div>
            <div class='up'>
                <Video img={require('../../images&videos/Images/bridalMakeover/bt8.png')} video={require('../../images&videos/videos/hairVideos/video3.mp4')}/>
            </div>
            <div class='dn'>
                <Video img={require('../../images&videos/Images/bridalMakeover/bt9.png')} video={require('../../images&videos/videos/hairVideos/video4.mp4')}/>
            </div>
            <div class='up'>
                <Video img={require('../../images&videos/Images/bridalMakeover/bt10.png')} video={require('../../images&videos/videos/hairVideos/video5.mp4')}/>
            </div>
            
        </div>
    </div>
  )
}

export default Bridal