import React, { useEffect } from 'react'
import Video from './Video'
import Button from '../Button/Button'
import './Hair.css'
import './bridal1.css'  
import { Link } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Hair = () => {
    useEffect (()=>{
        document.body.scrollTop = 0;
        Aos.init({duration:2000});
      },[])
  return (
     <div style={{width:"80%", margin:'auto',backgroundImage:`url(${require('../../images&videos/Images/hairMakeover/hair_background.png')})`,backgroundRepeat:'no-repeat',backgroundSize: '50% 70%',backgroundPosition:'left'}}>
        <div class='hair_hade'>
            <div class='div_h1'></div>
            <div style={{textAlign:"right"}}> 
                <h1 style={{textAlign:"right",}} data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='hfont_id'>Hair Packages</h1>
                <h1 style={{textAlign:"right"}} data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class="hSfont_id">Hair Cutting</h1>
                <br />
                <Link to="/hair">
                    <div style={{float:'right'}}>
                        <Button width={120} dWidth={150} color={"black"}  name={"View Details"} scolor={"black"}/>
                    </div>
                </Link>
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
        <div class='bridal_tret1'>
            <div class='up'>
                <Video img={require('../../images&videos/Images/hairMakeover/ht6.png')}  video={require('../../images&videos/videos/hairVideos/video1.mp4')}/>
            </div>
            <div class='dn'>
                <Video img={require('../../images&videos/Images/hairMakeover/ht7.png')} video={require('../../images&videos/videos/hairVideos/video2.mp4')}/>
            </div>
            <div class='up'>
                <Video img={require('../../images&videos/Images/hairMakeover/ht8.png')} video={require('../../images&videos/videos/hairVideos/video3.mp4')}/>
            </div>
            <div class='dn'>
                <Video img={require('../../images&videos/Images/hairMakeover/ht9.png')} video={require('../../images&videos/videos/hairVideos/video4.mp4')}/>
            </div>
            <div class='up'>
                <Video img={require('../../images&videos/Images/hairMakeover/ht10.png')} video={require('../../images&videos/videos/hairVideos/video5.mp4')}/>
            </div>
        </div>
     </div>
  )
}

export default Hair