import React, { useEffect } from 'react'
import './Beauty.css'
import Video from './Video'
import Button from '../Button/Button'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'


const Beauty = () => {
    useEffect (()=>{
        document.body.scrollTop =0;
        Aos.init({duration:2000});
      },[])
  return (
    <div style={{width:'80%',margin:'auto', textAlign:'left',backgroundImage:'url(images/homepage/make-up-brush1.png)',backgroundRepeat:'no-repeat',backgroundSize: '50% 100%',backgroundPosition:'right'}}>
    <h1 class='hfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">Beauty Packages</h1>
    <h1 class='hSfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0"> Facial Treatment</h1>
    <br />
    <Link to='/service' style={{textDecoration:'none'}}>
        <Button width={120} dWidth={150} color={"black"}  name={"View Details"} scolor={"black"}/>
    </Link>
    <br />
    <br />
    <div class='bridal_tret2'>
        <div class='up'>
            <Video img={require('../../images&videos/Images/beautyMakeover/serviceO1.png')} video={require('../../images&videos/videos/hairVideos/video1.mp4')}/>
        </div>
        <div class='dn'>
            <Video img={require('../../images&videos/Images/beautyMakeover/serviceO9.png')} video={require('../../images&videos/videos/hairVideos/video2.mp4')}/>
        </div>
        <div class='up'>
            <Video img={require('../../images&videos/Images/beautyMakeover/serviceO8.png')} video={require('../../images&videos/videos/hairVideos/video3.mp4')}/>
        </div>
        <div class='dn'>
            <Video img={require('../../images&videos/Images/beautyMakeover/serviceO7.png')} video={require('../../images&videos/videos/hairVideos/video4.mp4')}/>
        </div>
        <div class='up'>
            <Video img={require('../../images&videos/Images/beautyMakeover/serviceO6.png')} video={require('../../images&videos/videos/hairVideos/video5.mp4')}/>
        </div>
        
    </div>
</div>
  )
}

export default Beauty