import React from 'react'
import Button from '../Button/Button'
import Aos from 'aos'
import 'aos/dist/aos.css'
import './AboutUs.css'
import '../font.css'
import { useEffect } from 'react'
import Form from '../Form/Form'


const AboutUs = () => {
    useEffect (()=>{
        Aos.init({duration:2000});
      },[])
  return (
    <div style={{marginTop:'84px'}}>
        <div class='about_head'>
            <div>
                <div class='aboutDiv1'>
                    <h1 data-aos='fade-up' class='hfont_id' style={{textAlign:'left'}}>About us</h1>
                    <div data-aos='fade-up' style={{width:'30%', height:'5px', border:'1px solid black',borderRadius:'5px', background:'black'}}></div>
                    <br />
                    <p data-aos='fade-up' style={{textAlign:'justify'}}>Today Newstyle has numerous branches as their standalone store as well as outlets in Gujarat. <span style={{fontWeight:'bold'}}>Mr. Dilip Bajaniya</span> is the director of Newstyle beauty center. He is man with creative ideas. He has well trained team with new techniques advance style. they belive in using technology and branded producys. His salon looks very mordern and ambience is very soothing & calming.</p>
                    <div data-aos="zoom-out" style={{width:'200px', margin:'60px auto 0'}}>
                        <img style={{width:'100%'}} src={require('../../images&videos/Images/aboutus/MaskGroup4.png')} alt="" />
                    </div>
                </div>
            </div>
            <div style={{backgroundImage:`url(${require('../../images&videos/Images/aboutus/background_black.png')})`,backgroundRepeat:'no-repeat',backgroundSize: '80% 60%  ' ,backgroundPosition:'top 0% right 0%'}}> 
                <div data-aos='fade-up-right' style={{width:'300px', margin:'30% 0 0'}}>
                    <img style={{width:'100%', height:'100%'}} src={require('../../images&videos/Images/aboutus/Image12.png')} alt="" />
                </div>
            </div>
        </div> 

        {/*----------paragraf----------  */}

        <div style={{textAlign:'justify', width:"80%",margin:'30px auto'  }}>
            <div style={{maxWidth:'600px'}}> 
                 <h1 data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='ahSfont_id'>Newstyle beauty center is very famous name in charotar district Anand Gujrat For ladies hair, Beauty & Breidal Treatments. In Bridal make over we have enormuos reputation,</h1>
            </div>
        </div> 
        <hr style={{width:'50%', float:'right'}} />

        {/* -------------Our Team------------ */}

        <div style={{width:'80%', margin:'100px auto',}}>
            <h1 data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='hfont_id' style={{textAlign:'center'}}>Our Team</h1>

            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true" style={{height:'450px'}}>
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                    <img style={{height:'450px'}} src={require('../../images&videos/Images/aboutus/Image13.png')} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item">
                    <img style={{height:'450px'}} src={require('../../images&videos/Images/aboutus/Image13.png')} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item">
                    <img style={{height:'450px'}} src={require('../../images&videos/Images/aboutus/Image13.png')} class="d-block w-100" alt="..."/>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>

        {/* ----------------Become a professional--------- */}

        <div class="about_pro" style={{backgroundImage:`url(${require('../../images&videos/Images/homepage/become.png')})`,backgroundRepeat:'no-repeat',backgroundSize: '100% 100%'}}>
            <div style={{width:"90%",margin:"auto",}}><h1 data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='hfont_id'>Become a peofessional Makeup Artist</h1></div>
            <div style={{ display:'grid',justifyContent:'center', width:"60%", textAlign:'center',margin:"auto"}}>
                <p data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, send do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nastrud exercitation ullamco lab commo</p>
                <div style={{margin:'auto'}}> 
                <Button width={110} dWidth={130} color={"white"}  name={"Read More"} scolor={"white"}/>    
                </div>
            </div>
         </div>
         {/* --------form---------- */}
         <div>
            <Form/>
         </div>
    </div>
  )
}

export default AboutUs