import React, { useEffect } from 'react'
import './form.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import '../font.css'
import Button from '../Button/Button'
import { Link } from 'react-router-dom'

const Form = () => {

    useEffect (()=>{
        Aos.init({duration:2000});
      },[])
  return (
    <div class="form" > 
        <div >  
            <div data-aos='fade-up'>
               <h3 class='hSfont_id' data-aos='fade-up'>Branch 1</h3>
               <p data-aos='fade-up'>401, Sanket Tower, Nr. Grid, Anand 388001, Gujarat - India </p>
               <p data-aos='fade-up'>Phone: +91-96622-47326</p>
               <p data-aos='fade-up'>Phone: +91-2692-247724</p>
               <p data-aos='fade-up'>Email: beauty@newstyleanand.com</p>
               <div style={{margin:'auto 20%'}}> 
              <Link to='/book-an-appointment' style={{textDecoration: 'none'}}>
                  <Button width={180} dWidth={220} color={"white"} name={"Book an Appointment"} scolor={"white"}/>
              </Link>
               </div>
            </div>
            <div data-aos='fade-up'>
               <h3 class='hSfont_id' data-aos='fade-up'>Branch 1</h3>
               <p data-aos='fade-up'>401, Sanket Tower, Nr. Grid, Anand 388001, Gujarat - India </p>
               <p data-aos='fade-up'>Phone: +91-96622-47326</p>
               <p data-aos='fade-up'>Phone: +91-2692-247724</p>
               <p data-aos='fade-up'>Email: beauty@newstyleanand.com</p>
               <div style={{margin:'auto 20%'}}> 
              <Link to='/book-an-appointment' style={{textDecoration: 'none'}}>
                  <Button width={180} dWidth={220} color={"white"} name={"Book an Appointment"} scolor={"white"}/>
              </Link>
               </div>
            </div>
            <div data-aos='fade-up'>
               <h3 class='hSfont_id' data-aos='fade-up'>Branch 1</h3>
               <p data-aos='fade-up'>401, Sanket Tower, Nr. Grid, Anand 388001, Gujarat - India </p>
               <p data-aos='fade-up'>Phone: +91-96622-47326</p>
               <p data-aos='fade-up'>Phone: +91-2692-247724</p>
               <p data-aos='fade-up'>Email: beauty@newstyleanand.com</p>
               <div style={{margin:'auto 20%'}}> 
              <Link to='/book-an-appointment' style={{textDecoration: 'none'}}>
                  <Button width={180} dWidth={220} color={"white"} name={"Book an Appointment"} scolor={"white"}/>
              </Link>
               </div>
            </div>
        </div>
    </div>
  )
}

export default Form