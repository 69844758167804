 
import {React } from 'react'; 
import { Route, Routes } from 'react-router-dom'; 
import './App.css'; 
import AboutUs from './components/About_us/AboutUs';
import BookAppointment from './components/Book_Appointment/BookAppointment';
import Bridal_service from './components/Bridal_service/Bridal_service';
import Contact from './components/contact/Contact';
import Footer from './components/Footer/Footer'; 
import Hair_service from './components/Hair_service/Hair_service';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import ScrollToTop from './components/ScrollToTop';
import Service from './components/Service/Service';
import './messaging_init_in_sw' 

function App() { 
 
  return (
    <div className="App">
       <ScrollToTop/>
       <Header/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about-us' element={<AboutUs/>}/>
          <Route path='/beauty' element={<Service/>}/>
          <Route path='/contact-us' element={<Contact/>}/>
          <Route path='/book-an-appointment' element={<BookAppointment/>}/>
          <Route path='/bridal' element={<Bridal_service/>}/>
          <Route path='/hair' element={<Hair_service/>}/> 
        </Routes>
       <Footer/> 
    </div>
  );
}

export default App;
