import React, { useEffect } from 'react'
import './Service.css'
import '../font.css'
import Button from '../Button/Button'
import Form from '../Form/Form'
import Aos from 'aos'
import 'aos/dist/aos.css'
import Video from '../Home/Video'

const Service = () => {
    useEffect (()=>{
        Aos.init({duration:2500});
      },[])
  return (
    <div style={{margin:'84px 0'}}>
        <div class='serviceHead'>
            <div style={{backgroundImage:`url(${require('../../images&videos/Images/beautyMakeover/background_black.png')})`,backgroundRepeat:'no-repeat',backgroundSize:'50% 80%'}}>
                <div  data-aos='fade-up-left' style={{maxWidth:'300px',margin:'10% 20%'}}>
                    <img style={{width:'100%'}} src={require('../../images&videos/Images/beautyMakeover/service_beauty.png')} alt="" />
                </div>
            </div>
            <div style={{color:'black', textAlign:'left', display:'grid', alignItems:'center', width:'90%', margin:'auto'}}>
                <div style={{maxWidth:'400px',}}>
                    <h1 data-aos='fade-up' class='hfont_id'>Beauty Makeover</h1>
                    <p data-aos='fade-up' style={{textAlign:'justify'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercition ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    <Button width={180} dWidth={220} color={"black"} name={"Book an Appointment"} scolor={"black"}/> 
                </div>
            </div>
        </div>
        {/* --- */}
        <hr style={{width:'50%',float:'right'}}/>
        {/*----------what we offer---------  */}
        <div style={{width:'85%', margin:' 50px auto'}}>
            <h1 data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='hfont_id'>What We Offer</h1>
            <br /><br />
            <div class='bridal_tret2'>
                <div class='up'>
                    <Video img={require('../../images&videos/Images/beautyMakeover/serviceO1.png')} video={require('../../images&videos/videos/hairVideos/video1.mp4')}/>
                </div>
                <div class='dn'>
                    <Video img={require('../../images&videos/Images/beautyMakeover/serviceO9.png')} video={require('../../images&videos/videos/hairVideos/video2.mp4')}/>
                </div>
                <div class='up'>
                    <Video img={require('../../images&videos/Images/beautyMakeover/serviceO8.png')} video={require('../../images&videos/videos/hairVideos/video3.mp4')}/>
                </div>
                <div class='dn'>
                    <Video img={require('../../images&videos/Images/beautyMakeover/serviceO7.png')} video={require('../../images&videos/videos/hairVideos/video4.mp4')}/>
                </div>
                <div class='up'>
                    <Video img={require('../../images&videos/Images/beautyMakeover/serviceO6.png')} video={require('../../images&videos/videos/hairVideos/video5.mp4')}/>
                </div> 
            </div>
            {/* <div data-aos='fade-up' class='service_offer'>
                <div class='serviceUp'>
                    <img style={{width:'100%', height:'100%',objectFit:'cover'}} src={require('../../images&videos/Images/beautyMakeover/serviceO11.png')} alt="" />
                </div>
                <div class='serviceDun'>
                    <img style={{width:'100%', height:'100%',objectFit:'cover'}} src={require('../../images&videos/Images/beautyMakeover/serviceO2.png')} alt="" />
                </div>
                <div  class='serviceUp'>
                    <img style={{width:'100%', height:'100%',objectFit:'cover'}} src={require('../../images&videos/Images/beautyMakeover/serviceO3.png')} alt="" />
                </div>
                <div class='serviceDun'>
                    <img style={{width:'100%', height:'100%',objectFit:'cover'}} src={require('../../images&videos/Images/beautyMakeover/serviceO4.png')} alt="" />
                </div>
                <div  class='serviceUp'>
                    <img style={{width:'100%', height:'100%',objectFit:'cover'}} src={require('../../images&videos/Images/beautyMakeover/serviceO5.png')} alt="" />
                </div>
            </div>  */}
        </div>

        {/* ----------beauty facial----------- */}

        <div class='facial'>
            <div>
                <div style={{margin:'10px 10%',textAlign:'justify'}}>
                    <h1 data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='hfont_id'>Beauty Facial</h1>
                    <p data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    <Button width={180} dWidth={220} color={"black"} name={"Book an Appointment"} scolor={"black"}/>  
                </div>
            </div>
            <div>
                <div data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" style={{masHeight:'400px'}}>
                    <img style={{width:'100%',height:'100%'}} src={require('../../images&videos/Images/beautyMakeover/facial.png')} alt="" />
                </div>
            </div>
        </div>

        {/* ------------- */}
        <hr style={{width:'50%',margin:'80px 0'}}/>

        {/* -------------our pricing-------- */}

        <div>
            <h1 data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='hfont_id'>Our Pricing</h1>
            <div class='service_price'>
                <div data-aos='fade-up'>
                    <h1 data-aos='fade-up' class='hSsfont_id'>Trial</h1>
                    <p data-aos='fade-up'>get married ? adviced to make a trail to match expectation with the artist</p>
                    <h1 data-aos='fade-up' class='hSsfont_id'>Rs. 1000</h1>
                </div>
                <div data-aos='fade-up'>
                    <h1 data-aos='fade-up' class='hSsfont_id'>Bridal</h1>
                    <p data-aos='fade-up'>get married ? adviced to make a trail to match expectation with the artist</p>
                    <h1 data-aos='fade-up' class='hSsfont_id'>Rs. 1000</h1>
                </div>
                <div data-aos='fade-up'>
                    <h1 data-aos='fade-up'class='hSsfont_id'>Air Brush</h1>
                    <p data-aos='fade-up'>get married ? adviced to make a trail to match expectation with the artist</p>
                    <h1 data-aos='fade-up' class='hSsfont_id'>Rs. 1000</h1>
                </div>
                <div data-aos='fade-up'>
                    <h1 data-aos='fade-up' class='hSsfont_id'>Customized</h1>
                    <p data-aos='fade-up'>get married ? adviced to make a trail to match expectation with the artist</p>
                    <h1 data-aos='fade-up' class='hSsfont_id'>Rs. 1000</h1>
                </div>
            </div>
        </div>

        {/* --------form----------- */}
        <div>
            <Form/>
        </div>
    </div>
  )
}

export default Service