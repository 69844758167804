import React from 'react'
import HoverVideoPlayer from 'react-hover-video-player'
import './video.css'

const Video = ({img,video}) => {
  return (
    <HoverVideoPlayer
      videoSrc={video}
      typeof='video/mp4'
      pausedOverlay={
        <img
          src={img}
          alt=""
          style={{
            position:'relative', 
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      }
      loadingOverlay={
        <div className="loading-overlay" >
          <div className="loading-spinner"  />
        </div>
      }
    />
  )
}

export default Video