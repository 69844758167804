import Button from '../Button/Button'
import React, { useEffect, useState } from 'react'
import './home.css' 
import Form from '../Form/Form';
import Aos from 'aos'
import 'aos/dist/aos.css'
import '../font.css'
import Bridal from './Bridal';
import Hair from './Hair';
import Beauty from './Beauty';
import { Link } from 'react-router-dom';
  


const serviceData = [
  {
    title: "Makeup",
    image: `${require('../../images&videos/Images/homepage/Group10.png')}`,
    text: "Makeup Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer ",
    link: "#",
  },
  {
    title: "Treatment",
    image: `${require('../../images&videos/Images/homepage/tratmant.png')}`,
    text: "Treatment Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer ",
    link: "#",
  },
  {
    title: "Skin",
    image: `${require('../../images&videos/Images/homepage/skin.png')}`,
    text: "Skin Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer ",
    link: "#",
  },
  {
    title: "Color",
    image: `${require('../../images&videos/Images/homepage/color.png')}`,
    text: "Color Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer ",
    link: "#",
  },
  {
    title: "Hair",
    image: `${require('../../images&videos/Images/homepage/hair.png')}`,
    text: "Hair Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer ",
    link: "#",
  },
];

const Home = () => {

  
  const [service, setService] = useState("Makeup")  
  const selectService = serviceData.filter((el)=>el.title == service)
  useEffect (()=>{
    document.body.scrollTop = 500;
    Aos.init({duration:2000});
  },[])  

  if( document.body.scrollTop = 0){ 
    console.log("kamesh")
  }
  
  return (
    <div class="home" >
      <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="false" style={{height:"110vh",}}>
        <div class="carousel-indicators" > 
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>

        <div class="carousel-inner">
          <div class="carousel-item active" >
          <img style={{height:"110vh",objectFit:'cover'}} src={require('../../images&videos/Images/homepage/Group601.png')} class="d-block w-100" alt="..."/>
            <div class="carousel-caption d-none d-md-block" style={{height:'110vh'}}>
              <div style={{margin:'30% 0'}}> 
                <h1 class='hfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">We Are</h1>
                <h1 class='hHfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">New Style</h1>
              </div>
            </div>
          </div>
          <div class="carousel-item">
          <img style={{height:"110vh",objectFit:'cover'}} src={require('../../images&videos/Images/homepage/Group601.png')}  class="d-block w-100" alt="..."/>
          <div class="carousel-caption d-none d-md-block" style={{height:'110vh'}}>
              <div style={{margin:'30% 0'}}> 
                <h1 class='hfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">We Are</h1>
                <h1 class='hHfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">New Style</h1>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img style={{height:"110vh",objectFit:'cover'}} src={require('../../images&videos/Images/homepage/Group601.png')}  class="d-block w-100" alt="..."/>
            <div class="carousel-caption d-none d-md-block" style={{height:'110vh'}}>
              <div style={{margin:'30% 0'}}> 
                <h1 class='hfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">We Are</h1>
                <h1 class='hHfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">New Style</h1>
              </div>
            </div>
          </div>
        </div>

        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev" style={{ width:"50px",height:"50px",position:'fixd',top:'45%',left:'30px'}}>
          <span class="carousel-control-prev-icon" aria-hidden="true" style={{transform:` rotate(90deg)`}}>
            <div style={{position:'relative',bottom:'4px',right:'2px'}}>
              <Button width={40} dWidth={50} color={"white"}  name={""} scolor={"white"}/> 
            </div>
          </span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next" style={{width:"50px",height:"50px",position:'fixd',top:'55%',left:'30px'}}>
          <span class="carousel-control-next-icon" aria-hidden="true" style={{transform:` rotate(90deg)`}}>
            <div style={{position:'relative',bottom:'4px',right:'5px'}}>
              <Button width={40} dWidth={50} color={"white"}  name={""} scolor={"white"} style={{height:"40px"}}/>  
            </div>
          </span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      
      {/* -----------------our department--------------------- */}
      <div>  
        <div  style={{width:'20%' , border:"1px solid", background:'black', margin :'50px 0' }}></div>
       
        <div class="department_div">
          <div> 
              <h1 class='hSfont_id'  data-aos='fade-up' style={{textAlign:'justify'}}>Newstyle beauty centre is very famous name in Charotar district Anand Gujarat for ladies Hair. Beauty & Bridal Treatments in Bridal make over we have enormous reputation.</h1>
            <br />
           <Link to='/about-us' style={{textDecoration:'none',}}>
              <Button width={110} dWidth={150} color={"black"}  name={"Read More"} scolor={"black"} />
            </Link> 
            <br /> 
            <div data-aos="zoom-out" style={{maxWidth:'500px',maxHeight:'450px', padding:'10px',borderRadius:'50%'}}>
              <img style={{width:'100%',height:'100%',borderRadius:'50%',objectFit:'cover' }}   src={require('../../images&videos/Images/homepage/Group602.png')} alt="" />
            </div> 
          </div> 
          <div> 
            <div class="hide_div">  
                <div data-aos="zoom-out" style={{width:'55%',height:'40%', padding:'10px',borderRadius:'50%',margin:'60px 10px'}} >
                  <img style={{width:'100%',height:'100%',borderRadius:'50%',objectFit:'cover' }} src={require('../../images&videos/Images/homepage/MaskGroup4.png')} alt="" />
                </div> 
              <br /> 
                <div data-aos="zoom-out" style={{width:'45%',height:'40%', padding:'10px',borderRadius:'50%', margin:'0 100px'}}>
                  <img style={{width:'100%',height:'100%',borderRadius:'50%',objectFit:'cover' , }} src={require('../../images&videos/Images/homepage/Group603.png')} alt="" />
                </div>  
            </div>
            <br /> 
            <div style={{textAlign:'justify'}}>
              <p data-aos='fade-up'>Today Newstyle has numerous branches as their standalon stores as well as oulets ihn Gujarat. <span style={{fontWeight:'bold'}}>Mr. Dilip Bajaniya</span> is the director of newstyle beauty centre. He is man with creative ideas. He has well trained team with new techniques advance styles. They believe in using technology and branded products. His salon looks very mordern and ambience is very soothing & calming.</p>
            </div>
           
          </div>
        </div>
        <br />
        <br />
      </div>
       {/* --------------------our service--------------------- */}
       <div class="our_service" data-aos="zoom-in" >
        <br />
          <div class="service_head">  
             <div></div> 
              <h1 class='hSfont_id' data-aos='fade-up'>Our Service</h1> 
             <div></div>
          </div>

          <div class="service"> 
            <div style={{display:'grid',alignItems:'center'}}>                                 
                 {selectService.map((el)=>{
                  return(
                    <div style={{display:'grid',gridTemplateColumns:'30% 70%' ,width:'80%',height:'60%',margin:'auto',backgroundImage:`url(${require('../../images&videos/Images/homepage/hair-spies-background.png')})`,backgroundRepeat:'no-repeat',objectFit:'cover',backgroundSize: '300px' }}>
                      <div data-aos='fade-up' style={{width:'130px',height:'130px',padding:'5px',borderRadius:'50%'}}>
                        <img style={{width:'100%',height:'100%',borderRadius:'50%' }} src={el.image} alt="" />
                      </div>
                      <div style={{textAlign:'justify'}}>
                        <h1 class='hSfont_id' data-aos='fade-up' style={{color:'red'}}>{el.title}</h1>
                        <p data-aos='fade-up'>{el.text}</p>
                        <Button width={110} dWidth={150} color={"white"}  name={"Read More"} scolor={"white"}/>
                      </div>
                    </div>
                  )
                 }
                 )}
            </div>
 
            <div class="service_line"></div>
              <div style={{display:'grid',alignItems:'center',gap:'15px', justifyContent:'center', padding:"10px"}}>

                    <div style={{display:"flex", alignItems:'center'}} onClick={()=>setService("Makeup")}>
                      <div data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0" style={{width:'110px',height:'110px',borderRadius:'50%'}}>
                        <img style={{width:'100%',height:'100%',  }} src={require('../../images&videos/Images/homepage/Group10.png')} alt="" />
                      </div>
                      <div><h1 class='hSfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0">Makeup</h1></div>
                    </div> 

                    <div style={{display:"flex", alignItems:'center'}} onClick={()=>setService("Treatment")}>
                      <div><h1 class='hSfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0" >Treatment</h1></div>
                      <div data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0" style={{width:'120px',height:'120px',padding:'5px', border:'1px solid waite',borderRadius:'50%'}}>
                        <img style={{width:'100%',height:'100%',borderRadius:'50%',objectFit:'cover' }} src={require('../../images&videos/Images/homepage/tratmant.png')} alt="" />
                      </div>
                    </div>  

                    <div style={{display:"flex", alignItems:'center'}} onClick={()=>setService("Skin")}>
                      <div data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0" style={{width:'110px',height:'110px',borderRadius:'50%'}}>
                        <img style={{width:'100%',height:'100%'}} src={require('../../images&videos/Images/homepage/skin.png')} alt="" />
                      </div>
                      <div><h1 class='hSfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0">Skin</h1></div>
                    </div>
                    
                    <div style={{display:"flex", alignItems:'center',marginLeft:'90px'}} onClick={()=>setService("Color")}>
                      <div><h1 class='hSfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0">Color</h1></div>
                      <div data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0"style={{width:'120px',height:'120px',padding:'5px', border:'1px solid white',borderRadius:'50%'}}>
                        <img style={{width:'100%',height:'100%',borderRadius:'50%',objectFit:'cover' }} src={require('../../images&videos/Images/homepage/color.png')} alt="" />
                      </div>
                    </div>
  
                    <div style={{display:"flex", alignItems:'center'}} onClick={()=>setService("Hair")}>
                      <div data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0"style={{width:'110px',height:'110px',borderRadius:'50%'}}>
                        <img style={{width:'100%',height:'100%',borderRadius:'50%'}} src={require('../../images&videos/Images/homepage/hair.png')} alt="" />
                      </div>
                      <div><h1 class='hSfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0">Hair</h1></div>
                    </div> 
                    <br />
              </div>
          </div>
       </div>

       {/* ---------------------professional----------------- */}
       <div class="professional" style={{backgroundImage:`url(${require('../../images&videos/Images/homepage/become.png')})`,backgroundRepeat:'no-repeat',backgroundSize: '100% 100%'}}>
          <div style={{width:"90%",margin:"auto",}}><h1 data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='hfont_id'>Become a peofessional Makeup Artist</h1>
          <div style={{ width:"60%",margin:"auto"}}>
            <p data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, send do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nastrud exercitation ullamco lab commo</p>
            <div class='profe_butn'> 
               <Button width={110} dWidth={150} color={"white"}  name={"Read More"} scolor={"white"}/>    
            </div>
          </div> 
          </div>
       </div>

      {/*-------------bridal mackup-------------------------  */}
     
      <div style={{backgroundImage:`url(${require('../../images&videos/Images/hairMakeover/bb2.png')})`,backgroundRepeat:'no-repeat',backgroundSize: '100% 100%',backgroundPosition:'top'}} >
        <div class="bridal"> 
          <div style={{maxWidth:'500px', textAlign:'justify'}}>
              <h1 data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='hfont_id'>Bridal Makeover</h1>
              <p data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, send do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nastrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              <Link to='/book-an-appointment' style={{textDecoration: 'none'}}>
                  <Button width={180} dWidth={220} color={"black"} name={"Book an Appointment"} scolor={"black"}/>  
              </Link>
          </div>
        <div data-aos="zoom-out"> 
            <img style={{width:'90%',height:'100%',objectFit:'cover'}} src={require('../../images&videos/Images/homepage/bridel1.png')}  alt="" />
            <img  src={require('../../images&videos/starts/stars.png')} alt="" style={{width:'',width:"20%", position:'relative', top:'-105.3%',right:'20%'}} />
            <img  src={require('../../images&videos/starts/stars.png')} alt="" style={{width:'',width:"10%", position:'relative', top:'-100%',right:'30%'}} />
            <img  src={require('../../images&videos/starts/stars.png')} alt="" style={{width:'',width:"20%", position:'relative', top:'-40%',right:'65%'}} />
        </div>
        </div>
      </div>
      {/* ----------------Bridal Packages-------------------- */}
      <div>
         <Bridal/>
      </div>

      {/* -------------------hair makeover ---------------- */}
      <div style={{backgroundImage:`url(${require('../../images&videos/Images/bridalMakeover/bb1.png')})`,backgroundRepeat:'no-repeat',backgroundSize: '100% 100%',backgroundPosition:'bottom'}}> 
        <div class="bridal2">
          <div data-aos="zoom-out"> 
              <img style={{width:'100%',height:'100%',objectFit:'cover'}} src={require('../../images&videos/Images/homepage/tratmant.png')} alt="" />
          </div>
          <div></div>
          <div style={{ maxWidth:'500px',textAlign:'right'}}>
                <h1 data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='hfont_id' style={{}}>Hair Makeover</h1>
                <p data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" style={{textAlign:"justify",}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, send do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nastrud exercitation ullamco lab commo</p>
              <div style={{}}>
              <Link to='/book-an-appointment' style={{textDecoration: 'none'}}>
                <Button width={180} dWidth={220} color={"black"} name={"Book an Appointment"} scolor={"black"}/>  
              </Link>
              </div>
          </div>
        </div>
      </div>
      {/* ---------------------hair Packages----------- */}
      <div>
         <Hair/>
      </div>
      {/*-----------------------Beauty mackeover-----------  */}
      <div style={{backgroundImage: `url(${require('../../images&videos/Images/hairMakeover/bb2.png')})`,backgroundRepeat:'no-repeat',backgroundSize: '100% 100%',backgroundPosition:'top'}} >
        <div class="bridal"> 
          <div style={{maxWidth:'500px', textAlign:'justify'}}>
              <h1 data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='hfont_id'>Beauty Makeover</h1>
              <p data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, send do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nastrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              <Link to='/book-an-appointment' style={{textDecoration: 'none'}}>
                <Button width={180} dWidth={220} color={"black"} name={"Book an Appointment"} scolor={"black"}/>  
              </Link>
          </div>
        <div data-aos="zoom-out"> 
             <img style={{width:'90%',height:'100%',objectFit:'cover'}} src={require('../../images&videos/Images/beautyMakeover/service_beauty.png')}   alt="" />
             <img src={require('../../images&videos/starts/stars.png')} alt="" style={{width:'',width:"20%", position:'relative', top:'-40%',right:'48%'}} />
        </div>
        </div>
      </div>
      {/* -----------Beaty Tretmant -------------- */}
      <div>
        <Beauty/>
      </div>
      <Form/>
    </div>
  )
}

export default Home