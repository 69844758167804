import { initializeApp } from "firebase/app";
import { getMessaging,getToken } from "firebase/messaging";
// importScripts('https://www.gstatic.com/firebasejs/4.8.1/firebase-app.js');
// importScripts('https://www.gstatic.com/firebasejs/4.8.1/firebase-messaging.js');

const firebaseConfig = {
  apiKey: "AIzaSyBhEWyZ8UEtKY0popBrnINsehoKbLX15OY",
  authDomain: "newstyle-aaad7.firebaseapp.com",
  projectId: "newstyle-aaad7",
  storageBucket: "newstyle-aaad7.appspot.com",
  messagingSenderId: "428887188610",
  appId: "1:428887188610:web:6921b61ae735628ab9009b",
  measurementId: "G-4GPE1YFGET"
}; 


function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.'); 
        const app = initializeApp(firebaseConfig); 

        const messaging = getMessaging(app);
        getToken(messaging,{
          vapidKey:'BL62bKXXFFtfRtHlJOlpHU1NzfrcFud9WKMrBevBB67VnPIl12s-CbYbCNCwE1nhHrLoPw-fCrwVz34uWXaEAqY',
        }).then((currentToken)=>{
            if(currentToken){
                console.log('currentToken:', currentToken)
            }else{
                console.log('Can not get token')
            }
        });
      }else{
        console.log('Do not have permission.!');
      }
    });
}
requestPermission();

