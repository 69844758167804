import React from "react";
import './button.css'

const Button = ({width,name,dWidth,color,scolor}) => { 
  
  return (
    <div class="svg-wrapper" style={{width:`${dWidth}px`, height:"40px",}}>
      <svg height="40" width="auto" xmlns="http://www.w3.org/2000/svg" style={{transform:` rotate(358deg)`}} >
          <rect class="shape1" height="40px " width= {`${width}`} rx="100" stroke={scolor}/>  
      </svg>
          <div class="text" style={{color:`${color}`,fontFamily: 'Poppins' }}> {name} </div> 
      <svg height="40" width="auto" xmlns="http://www.w3.org/2000/svg" style={{ marginTop:'-64px',transform:` rotate(364deg)`}} >
          <rect class="shape1" height="40px " width= {`${width}`} rx="100" stroke={scolor}/>  
      </svg>
    </div>
  );
};

export default Button;
