import React from 'react';
import './footer.css'
import { AiFillLinkedin, AiOutlineFacebook, AiOutlineInstagram } from 'react-icons/ai';
import { FiTwitter } from 'react-icons/fi';

const Footer = () => {
  return (
    <div style={{backgroundColor:"black",color:'white',marginTop:'-20px' }}>
        <div class="footer">
            <div>
                <h3 class="logo">NEW<span style={{color:'red'}}>STYLE</span></h3>
                <p>Lorem ipsum solor sit amet, consectetur adipiscing elit, sed do eiusm</p>
                <div style={{display:"flex"}}>
                    <div style={{width:'70px', height:'70px'}}><AiOutlineFacebook style={{width:'100%'}}/> </div>
                    <div style={{width:'70px', height:'70px'}}><AiOutlineInstagram style={{width:'100%'}}/> </div>
                    <div style={{width:'70px', height:'70px'}}><FiTwitter style={{width:'100%'}}/> </div>
                    <div style={{width:'70px', height:'70px'}}><AiFillLinkedin style={{width:'100%'}}/> </div>
                </div>
            </div>
            <div>
                <ul style={{listStyle:"none", display:'grid',justifyContent:'left',padding:"10px"}}>
                    <li>Home</li>
                    <li>About</li>
                    <li>Service</li>
                    <li>Contact Us</li> 
                    <li>Book an Appointment</li> 
                </ul>
            </div>
            <div>
                <ul style={{listStyle:"none", display:'grid',justifyContent:'left',padding:"10px"}}>
                    <li>Skin</li> 
                    <li>Color</li>
                    <li>Hair</li> 
                    <li>Makeup</li>
                    <li>Treatment</li>
                </ul>
            </div>
            <div>
                <ul style={{listStyle:"none", display:'grid',justifyContent:'left',textAlign:'left',padding:'10px'}}>
                    <li>401, Sanket Tower, Nr. Grid, Anand 388001, Gujarat - India</li>
                    <br />
                    <li>+91-96622-47326</li>
                    <li>+91-2692-247724</li> 
                    <br />
                    <li> beauty@newstyleanand.com</li>
                    
                </ul>
            </div> 
        </div>
        <div style={{width:'80%', margin:'auto'}}>
            <hr style={{color:'white'}}/>
            <div class="footer_bottom">
                <p style={{textAlign: 'left'}} >Privacy Policy</p> 
                <p style={{textAlign: 'left'}}>@hilab solution copyright</p>   
            </div>
        </div>
    </div>
  )
}

export default Footer