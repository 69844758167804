import Aos from 'aos'
import React, { useEffect } from 'react'
import Button from '../Button/Button'
import Form  from '../Form/Form'
import './contact.css'

const Contact = () => {
  useEffect(()=>{
    Aos.init({duration:2000});
  },[])
  return (
    <div>
        <div class='contact'>
            <div>
                <h1 class='hfont_id' data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">Contact Us</h1>
                <div data-aos='fade-up' style={{width:'30%', height:'5px', border:'1px solid black',borderRadius:'5px', background:'black'}}></div>
                <br />
                <p>401,{<br/>}Sanket Tower, Nr. Grid, Anand 388001, Gujarat - India</p>
            </div>
            <div>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_rbrCDbss3kRmbeiZoslsXiTdENOYg9iXCA&usqp=CAU" alt="" />
            </div>
        </div>

        <div style={{width:'80%', background:'red',margin:'auto', color:'white', padding:'30px',borderRadius:'10px'}}>
          <div style={{maxWidth:'700px',display:'grid',alignItems:'center', margin:'5% auto' }}>
            <h1 class="hSfont_id">Contact With Us Now!</h1>
            <br />
            <input type="text" placeholder='FULL NAME' />
            <div class='cline'></div>
            <br />
            <input type="text" placeholder='PHONE NO.' />
            <div class='cline'></div>
            <br />
            <input type="text" placeholder='EMAIL ADDRESS'/>
            <div class='cline'></div>
            <br />
            <input type="text" placeholder='YOUR MESSAGE' />
            <div class='cline'></div>
            <br />
            <div style={{margin:'auto 35%'}}> 
                  <Button width={85} dWidth={105} color={"white"} name={"Submit"} scolor={"white"}/>
               </div>
          </div>
        </div>
        <Form/>
    </div>
  )
}

export default Contact