import React from 'react'
import Form from '../Form/Form'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const BookAppointment = () => {
    useEffect (()=>{
        Aos.init({duration:2000});
      },[])
  return (
    <div>
        <div style={{width:'80%', margin:'100px auto'}}>
            <h1 data-aos="fade-zoom-in"  data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" class='hfont_id'>
                Book an Appointment
            </h1>
        </div>
            <Form/> 
    </div>
  )
}

export default BookAppointment